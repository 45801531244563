<script>
import Layout from "../../layouts/main";
import CrmWidget from "@/components/widgets/Crm-widget";
import PageHeader from "@/components/Page-header";
import Campaigns from "@/components/widgets/Campaigns";
import CrmWidget2 from "@/components/Crm-widget";
import userService from '../../../helpers/userService';
import errorHandler from '@/helpers/errorHandler'
import Axios from "axios";

/**
 * Dashboard component
 */
export default {
  page: {
    title: "Dashboard",
  },
  async created(){
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    if(userService.methods.userRole() == 0){
      await this.$store.dispatch('statistics/getStudentsStat').then( ()=>{}).catch( err => {
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      });
      await this.$store.dispatch('statistics/getCentersStat').then( ()=>{}).catch( err => {
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      });
      await this.$store.dispatch('statistics/getSessionsStat').then( ()=>{}).catch( err => {
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      });
      await this.$store.dispatch('statistics/getAssistantsStat').then( ()=>{}).catch( err => {
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      });
    }else if(userService.methods.userRole() == 1){
      await this.$store.dispatch('user/getProfile').then( async res =>{
          this.userCode = res.student_data.code;
    }).catch(err =>{
        errorHandler.methods.error(err);
    });
    }
    this.username = userService.methods.userName();
  },
  components: {
    Layout,
    CrmWidget,
    PageHeader,
    Campaigns,
    CrmWidget2,
  },
  data() {
    return {
      username: null,
      userCode: null,
      baseUrl: null,
      title: "Dashboard",
      items: [
        {
          text: "Dr.Micheal Labib",
        },
        {
          text: "Dashboards",
        },
        {
          text: "home",
          active: true,
        },
      ],
    };
  },
  computed:{
      widgetData(){ 
        return [{
            title: "Students",
            icon: "fas fa-user-graduate",
            value:  this.$store.getters['statistics/students'] | 0,
            color:'#2596be'
          },
          {
            title: "Assistants",
            icon: "fas fa-user-cog",
            value:  this.$store.getters['statistics/assistants'] | 0,
            color:'#3CB878'
          },
          {
            title: "Centers",
            icon: "fas fa-building",
            value:  this.$store.getters['statistics/centers'] | 0,
            color: '#ff8566'
          },
          {
            title: "Sessions",
            icon: "fas fa-calendar",
            value:  this.$store.getters['statistics/sessions'] | 0,
            color:'#bbdefb'
          },
        ]
      }
  },
  methods:{
    async downloadItem ({ url, label }) {
      await Axios.post(url, { responseType: 'blob',headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`} })
        .then(response => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(err =>{
            console.log(err)
            errorHandler.methods.errorMessage(err);
        })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="$store.getters['user/role'] == 0">
      <div
        v-for="data in widgetData"
        :key="data.title"
        class="col-xl-3 col-md-6"
      >
        <CrmWidget :title="data.title" :icon="data.icon" :value="data.value" :text="data.text" :color="data.color"/>
      </div>
      <b-button variant="success" pill class="ml-2"
        @click="downloadItem({url:`${baseUrl}admin/backup_database`,label: new Date().toDateString()})"
      >
        <i
        class="mdi mdi-download mr-2"
        ></i
        >Backup Database
      </b-button>
    </div>
    
    <div class="row" v-if="$store.getters['user/role'] == 1">
      <div class="col-lg-12">
        <div class="card bg-dark text-white">
            <div class="card-body">Welcome {{ username }} !</div>
        </div>
        <div class="text-center justify-content-center mb-3" v-if="this.userCode">
            <div class="auth-logo">
                <h1 class="logo-lg bg-success " style="width: 40%; margin: auto auto;border-radius: 5px;">
                    Student ID: {{ userCode }}
                </h1>
            </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-md-6">
            <CrmWidget2 :title="'Profile'" :icon="'fas fa-user'" :color="'#6297C3'" :desc="'Qrcode and personal informations'" :link="'/me'"/>
          </div>
          <div class="col-xl-3 col-md-6">
            <CrmWidget2 :title="'Attendance'" :icon="'fas fa-calendar-check'" :color="'#3CB878'" :desc="'your attendance details'" :link="'/myattendance'"/>
          </div>
          <div class="col-xl-3 col-md-6">
            <CrmWidget2 :title="'Announcements'" :icon="'fas fa-bell'" :color="'#EF5E29'" :desc="'Stay up-to-date'" :link="'/announcements'"/>
          </div>
          <div class="col-xl-3 col-md-6">
            <CrmWidget2 :title="'Lectures'" :icon="'fas fa-suitcase'" :color="'#bbdefb'" :desc="'Chemistry learning content'" :link="'/students/lectures'"/>
          </div>
          <div class="col-xl-3 col-md-6">
            <CrmWidget2 :title="'Scores'" :icon="'fas fa-star'" :color="'#7C73C0'" :desc="'your quizzes scores'" :link="'/students/scores'"/>
          </div>
          <div class="col-xl-3 col-md-6">
            <CrmWidget2 :title="'Balance'" :icon="'fas fa-hand-holding-usd'" :color="'#E8FFCE'" :desc="'credit and purchase history'" :link="'/redeem-code'"/>
          </div>
          <div class="col-xl-3 col-md-6">
            <CrmWidget2 :title="'Fantasy'" :icon="'fas fa-trophy'" :color="'#FFE17B'" :desc="'use your chances and get elements'" :link="'/my-fantasy'"/>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
