<script>
/**
 * Crm - Campaigns component
 */
export default {
    async created(){
        await this.$store.dispatch('statistics/getCentersStudentsStat').then(()=>{
            this.series =this.$store.getters['statistics/centersStats'].series;
            this.chartOptions.labels =this.$store.getters['statistics/centersStats'].labels;
        });
    },
    data() {
        return {
            series: [44, 55, 41],
            chartOptions: {
                legend: {
                    show: false,
                    position: "bottom",
                    horizontalAlign: "center",
                    verticalAlign: "middle",
                    floating: false,
                    fontSize: "14px",
                    offsetX: 0,
                    offsetY: 7
                },
                labels: [   "Total-1","Total-2","Total-3","Total-4","Total-5","Total-6",
                            "Total-7","Total-8","Total-9","Total-10","Total-11","Total-12",
                            "Total-13","Total-14","Total-15","Total-16","Total-17","Total-18",
                        ],
                colors: [   "#f7b84b", "#1abc9c", "#3bafda",
                            '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                            '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
                            '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
                            '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
                            '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                            '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
                            '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',],
                responsive: [{
                    breakpoint: 600,
                    options: {
                        chart: {
                            height: 210
                        },
                        legend: {
                            show: false
                        }
                    }
                }]
            }
        };
    }
};
</script>

<template>
<div class="card">
    <div class="card-body">
        <div class="d-flex justify-content-between">
            <h4 class="header-title">Centers capacity</h4>
            <!-- <div class="btn-group mb-2">
                <button type="button" class="btn btn-xs btn-light active">
                    Today
                </button>
                <button type="button" class="btn btn-xs btn-light">Weekly</button>
                <button type="button" class="btn btn-xs btn-light">Monthly</button>
            </div> -->
        </div>
        <div class="mt-3" dir="ltr">
            <apexchart class="apex-charts" type="donut" :options="chartOptions" height="260" :series="series"></apexchart>
        </div>
        <div class="row text-center mt-2">
            <div class="col-md-2" v-for="(item,index) in chartOptions.labels" :key="item">
                <h4 class="font-weight-normal mt-3">
                    <span>{{ series[index] }}</span>
                </h4>
                <p class="text-muted mb-0 mb-2">
                    <i class="mdi mdi-checkbox-blank-circle" :style="`color: ${chartOptions.colors[index]} !important`"></i> {{ item }}
                </p>
            </div>
        </div>
    </div>
</div>
<!-- end card-->
</template>
